/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"
import List from "../../components/List"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Professional Service"
      description="Offer powerful solutions to help professional services firms manage every aspect of their business, from customer relationships and projects to process outsourcing and financial management."
      linkSubPageGroupID="IndustriesGroup"
      infoList={[
        {
          title:
            "Mastering Time and Resources Efficiently Is The Key To Success",
          text: `Professional services firms are challenged with selling an intangible product that is difficult to quantify and ever-changing. Profitability requires that projects are estimated correctly and completed on time, while meeting or exceeding customer requirements. To succeed, companies must adopt strategic and comprehensive financial, project, and resource management practices. This can be accomplished with an information technology (IT) infrastructure that allows your people to share data, work together, and make effective decisions.`,
        },
        {
          title:
            "Microsoft Dynamics Business Central for Professional Service Industry",
          text: (
            <p>
              Microsoft Dynamics Business Central offers powerful solutions to
              help professional services firms manage every aspect of their
              business, from customer relationships and projects to process
              outsourcing and financial management. These solutions can
              streamline operations while improving the quality of customer
              interactions, and help making faster and smarter decisions.
              Microsoft Dynamics Business Central offers professional services
              companies the ability to:
              <h5 style={{ marginTop: "2rem" }}>
                Keep the customer front and center
              </h5>
              <List
                className="concise dark"
                items={[
                  {
                    text: `Track all customer-facing activities across multiple communication channels`,
                  },
                  {
                    text: `Improve communications with the customer through document sharing and safe Web interactions`,
                  },
                  {
                    text: `Increase customer responsiveness by improving business processes such as automated routing, notifications, approvals, and escalations`,
                  },
                ]}
              />
              <h5>Smoothly manage projects</h5>
              <List
                className="concise dark"
                items={[
                  {
                    text: `Gain visibility into all project information and key performance indicators to enable quick and accurate decision-making with integrated project accounting and project management`,
                  },
                  {
                    text: `Effectively allocate internal resources and increase billable hours`,
                  },
                  {
                    text: `Boost productivity by automating business processes such as billing and invoicing`,
                  },
                ]}
              />
            </p>
          ),
        },
      ]}
    />
  )
}
